import Cookie from '~/js/modules/Cookie';

function ChatPopup(popupBtn, popupElem) {
    this.windowWidth = document.documentElement.clientWidth;

    this._events = () => {

        window.addEventListener('resize', () => {
            this.windowWidth = document.documentElement.clientWidth;
            popupElem.classList.remove('chat-popup--show');
        });

        popupBtn.addEventListener('click', e => {
            if(this.windowWidth < 980) {
                e.preventDefault();
                if(!popupElem.classList.contains('chat-popup--show')) {
                    popupElem.classList.add('chat-popup--show');
                }
            }
        });

        popupElem.querySelector('.js-chat-close').addEventListener('click', () => {
            popupElem.classList.remove('chat-popup--show');
        });
    };

    this._init = () => {
        this._events();
    };

    this._init();
}

const footerPopupBtn = document.querySelector('.js-chat-btn');
const footerPopupBlock = document.querySelector('.js-chat-popup');

footerPopupBtn && footerPopupBlock && new ChatPopup(footerPopupBtn, footerPopupBlock);

// LIMITED OFFER POPUP

// const revealBtn = document.getElementById('js_reveal_btn');
// const revealCode = document.getElementById('js_reveal_block');

// revealCode.style.display = 'none';

// revealBtn.onclick = () => {
//     revealBtn.style.display = 'none';
//     revealCode.style.display = 'block';
// };

// const button = document.getElementById('js_copy_btn');

// button.addEventListener('click', () => {
//     const span = document.getElementById('js_reveal_code');
//     const content = span.textContent;

//     navigator.clipboard.writeText(content)
//         .then(() => {
//             button.textContent = 'Copied!';
//             setTimeout(() => {
//                 button.textContent = 'Copy Code';
//             }, 2500);
//         })
//         .catch((error) => {
//             console.error(error);
//         });
// });

const limitedPopupBtn = document.getElementById('js-magnific-popup');

function setNewsletterCookie() {
    const d = new Date();

    // 60sec*60mins*72hours = 259200 (3 day), 5*60*1000(5mins)
    d.setTime(d.getTime() + 259200000); // in milliseconds
    document.cookie = 'HIDE_LIMITED_POPUP=true;path=/;expires=' + d.toGMTString() + ';';
}

function timeOutPopup() {
    const hideLimitedPopup = Cookie.has('HIDE_LIMITED_POPUP') ? Cookie.get('HIDE_LIMITED_POPUP') : 'false';

    if (hideLimitedPopup === 'false') {
        limitedPopupBtn.click();
        setNewsletterCookie();
    }
}
if (limitedPopupBtn) {
    setTimeout(timeOutPopup, 20000);
}

$(document).mouseleave((e) => {
    if (e.clientY < 10 && limitedPopupBtn) {
        timeOutPopup();
    }
});

function checkOffsetTop(target) {
    const isDesktop = window.innerWidth >= '768';

    const top = isDesktop ? target.offsetTop - 30 : target.offsetTop + 20;

    return top;
}

window.addEventListener('load', () => {
    const main = document.querySelector('html, body');
    const urlScrollParam = window.location.search.split('scrollTo=')[1];

    if (!!urlScrollParam && urlScrollParam === 'cta-section') {
        const targetHasClass = document.querySelector(`.${urlScrollParam}`);
        const offsetTop = checkOffsetTop(targetHasClass);

        setTimeout(() => {
            main.scrollTo({
                top: offsetTop,
                behavior: 'smooth',
            });
        }, 1500);
    }
});
